/* eslint no-undef: "off"*/
/* eslint no-unused-expressions: "off"*/

import { Behaviors } from "./defaults";
import { Simulator, setDitherURL } from './initializer';

/**************************************************************************************************************************
 * Welcome To Fluid.JS
 *  This library will render a beautifully formulated fluid simulation in a HTML5Canvas. It works on most modern browsers
 *  and it is responsive to size. This simulation uses WebGL to utilize the device's GPU, allowing us to craft fast and
 *  performant hardware accelerated visuals without the use of plug-ins.
 *
 * @type {Fluid}
 **************************************************************************************************************************/
export default class Fluid {
    constructor(canvas, width = null, height = null){
        /* Dynamic Configurations */
        this.configure = new Behaviors();

        /* Set canvas to desired width and height*/
        this.canvas = canvas;
        canvas.width  = width ? width : canvas.clientWidth;
        canvas.height = height ? height : canvas.clientHeight;

        /* Initiate a new Simulator in Canvas with Configurations */
        this.simulator = new Simulator(canvas, this.configure);
    }

    /**
     * Activate Fluid Canvas
     *  Start activator loop in the Simulator.
     */
    activate() {
        /* Make Sure We Don't Cancel the Main Loop */
        this.configure.mapProps({
            cancel: false
        });

        /* Call the Activator */
        this.simulator.activate();
    }

    /**
     * Deactivate Fluid Canvas
     *  Notifies active fluid canvas to clear buffers and erase fluid from call stack.
     *
     */
    deactivate(){
        this.configure.mapProps({
            cancel: true
        });
    }

    /**
     * Reset Fluid Simulation
     *  Re-initializes WebGL context and activates simulation based on new context.
     *
     */
    reset() {
        this.simulator = new Simulator(this.canvas, this.configure);
        this.activate();
    }

    /**
     * Map Fluid Behaviors
     *  Calls the behavioral change function in the defaults' module.
     *
     * @param params: specific behavioral change value(s).
     *
     */
    mapBehaviors(params) {
        this.configure.mapProps(params);
    }

    /**
     * Set Fluid as Background
     *  Pushes canvas back in z space and absolutely positions it.
     *
     * @param shouldSet: true sets it, false un-sets it. Defaults to true.
     *
     */
    setAsBackground(shouldSet = true) {
        /* Pushes back in Z Index */
        this.canvas.style.zIndex = shouldSet ? '-99' : '0';

        /* Position Absolutely */
        this.canvas.style.position = shouldSet ? 'fixed' : 'relative';
    }

    /**
     * Apply Background
     *  Sets fluid canvas's background and resets view.
     *
     * @param mode: Image, Gradient, or Solid. The type of background to be applied.
     * @param value: The value to apply to the image type.
     * @param options: Additional options to configure background (optional)
     *
     */
    applyBackground(mode, value, options = null) {
        mode = mode.toLowerCase();

        /* Remove Canvas Background */
        this.mapBehaviors({
            transparent: true
        });

        /* Check for Background Type */
        switch (mode) {
            case 'gradient': gradient();
                break;
            case 'image': image();
                break;
            case 'solid':
            default:
                canvas.style.backgroundColor = value;
        }

        /** Set to Gradient
         *  Sets canvas background value to desired gradient.
         */
        function gradient() {
            /** Gradient
             *  Holds the gradient css statement.
             *
             * @type {string}
             */
            let gradient = "";

            /* Configure Gradient to Options */
            switch (options) {
                case 'radial':
                    gradient = "radial-gradient(" + value + ")";
                    break;
                case 'conic':
                    gradient = "conic-gradient(" + value + ")";
                    break;
                case 'repeating-linear':
                    gradient = "repeating-linear-gradient(" + value + ")";
                    break;
                case 'repeating-radial':
                    gradient = "repeating-radial-gradient(" + value + ")";
                    break;
                case 'linear':
                default:
                    gradient = "linear-gradient(" + value + ")";
            }

            /* Set Gradient Dom Style */
            canvas.style.backgroundImage = gradient;
        }

        /** Set to Image
         *  Sets canvas background image value to desired image and configure styles.
         */
        function image() {
            /* Set background image to desired URL, throw error if invalid URL */
            canvas.style.backgroundImage = "url('" + value + "')";

            /* Modify CSS Properties */
            if(options){
                /* Set Repeat */
                canvas.style.backgroundRepeat   = options.repeat   ? 'repeat' : 'no-repeat';

                /* Set Position */
                canvas.style.backgroundPosition = options.position ? options.position : 'center';

                /* Set Size */
                canvas.style.backgroundSize     = options.size     ? options.size : 'contain';

                /* Set Color */
                canvas.style.backgroundColor    = options.color    ? options.color : 'none';
            }
        }
    }

    /**
     * Set Dither URL
     *  Sets the URL to an image to be used for dithering. This method is only responsible for calling
     *  the action in the initializer. The dither will not be applied until the next activation call.
     *
     * @param url: Path to dither in root directory.
     */
    static setDitherURL(url){ setDitherURL(url); }
};

/*TODO
*  Add Functionality:
*       Clamp Inputs
*       Reset Behaviors
*       Random Pointer
*       Fix Bloom
*  Comment:
*       Initializer
*       Defaults
*  Refactor and Optimize:
*       Input values */

