/* eslint no-undef: "off"*/
/* eslint no-unused-expressions: "off"*/
/* eslint default-case: "off"*/
/* eslint no-sequences: "off"*/

import * as defaults from "./defaults";
import * as dither from "../assets/dither.png";

/**
 * Dither Default Directory Location
 *  Holds the value for the default directory location of the dither file. This can be changed with
 *  the `setDitherURL` function.
 *
 * @type {string}
 */
let ditherURL = "./assets/dither.png";

/********************************************************************************************************
 * Simulator
 *  The work horse behind the beauty. Simulator is responsible for parsing our canvas for the
 *  WebGL context and rendering to that context.
 *
 *  The worker object takes two parameters:
 *      canvas: The canvas that will be parsed for WebGL's context and evidently rendered too.
 *      props: These are the behaviors that will be used to fuel the simulator.
 *
 ********************************************************************************************************/
export class Simulator {
    /*****************************************************************************************************
     * Initiate WebGL Object
     *  Parses a canvas for the appropriate WebGL context. It is very important that we make sure not
     *  to load the wrong version, as browsers like IE11 are not able to work with the latest WebGL
     *  implementations at the moment.
     *
     *  The context we load will influence the color formats we use to compile our shader programs. We
     *  will be using the GLslang in defaults to create shaders then compile them with the `GLProgram`
     *  class.
     *
     *  Constructor also compile the shader programs we'll use for the actual rendering. Without
     *  these the simulation would just be a blank screen (so treat these guys nice😎).
     *
     * @param canvas: This is the canvas we will be getting the context from. Evidently this will also be
     * the object we render to.
     *
     * @param props: We will change some behaviors depending on the browser we are working with.
     *
     ******************************************************************************************************/
    constructor(canvas, props) {
        /* Try and get the latest WebGL version */
        let webGL = canvas.getContext('webgl2', defaults.DRAWING_PARAMS);

        /**
         * Is WebGL v2?
         *  Determines if we have a context for the latest WebGL version. WebGL will return null if the canvas that
         *  was passed does not contain the latest version. So we use the double bang operator to return WebGl's
         *  truthy value.
         *
         * @type {boolean}: True if WebGL v2 was successfully retrieved.
         */
        const isWebGL2 = !!webGL;

        /* If we can't use the latest WebGL Version, we'll get the experimental version or version 1 */
        if (!isWebGL2)
            webGL = canvas.getContext('webgl', defaults.DRAWING_PARAMS) || canvas.getContext('experimental-webgl', defaults.DRAWING_PARAMS);

        /* Color Formats Data */
        let formatRGBA;
        let formatRG;
        let formatR;
        let halfFloat;
        let supportLinearFiltering;

        /* Get color filter extensions */
        if (isWebGL2) {
            webGL.getExtension('EXT_color_buffer_float'); // todo: value goes no were
            supportLinearFiltering = webGL.getExtension('OES_texture_float_linear');
        } else {
            halfFloat = webGL.getExtension('OES_texture_half_float');
            supportLinearFiltering = webGL.getExtension('OES_texture_half_float_linear');
        }
        const HALF_FLOAT_TEXTURE_TYPE = isWebGL2 ? webGL.HALF_FLOAT : halfFloat.HALF_FLOAT_OES;

        /* Color to be shown when we clear the buffer (i.e. nothing) */
        webGL.clearColor(0.0, 0.0, 0.0, 0.0);

        /* Retrieve supported RGBA, RG, and R formats */
        if (isWebGL2) {
            formatRGBA = getSupportedFormat(webGL.RGBA16F, webGL.RGBA, HALF_FLOAT_TEXTURE_TYPE);
            formatRG = getSupportedFormat(webGL.RG16F, webGL.RG, HALF_FLOAT_TEXTURE_TYPE);
            formatR = getSupportedFormat(webGL.R16F, webGL.RED, HALF_FLOAT_TEXTURE_TYPE);
        } else {
            formatRGBA = getSupportedFormat(webGL.RGBA, webGL.RGBA, HALF_FLOAT_TEXTURE_TYPE);
            formatRG = getSupportedFormat(webGL.RGBA, webGL.RGBA, HALF_FLOAT_TEXTURE_TYPE);
            formatR = getSupportedFormat(webGL.RGBA, webGL.RGBA, HALF_FLOAT_TEXTURE_TYPE);
        }

        /* Get color formats */
        let colorFormats = {
            formatRGBA,
            formatRG,
            formatR,

            halfFloatTexType: HALF_FLOAT_TEXTURE_TYPE,
            supportLinearFiltering
        };

        /* Adjust Properties */
        if (!colorFormats.supportLinearFiltering)
            props.mapProps({ render_shaders: false, render_bloom: false });

        /* Compile raw shader sources to binary data for shader program */
        const SHADER = {
            /* 👑 Vertex Shader 👑 (i.e. the base/boss/dictator) */
            vertex               : compileShader(webGL.VERTEX_SHADER, defaults.SHADER_SOURCE.vertex),

            /* 🧩 Fragment Shaders (i.e. the pieces to the puzzle) */
            clear                    : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.clear),
            color                    : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.color),
            background               : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.background),
            display                  : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.display),
            displayBloom             : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.displayBloom),
            displayShading           : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.displayShading),
            displayBloomShading      : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.displayBloomShading),
            bloomPreFilter           : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.bloomPreFilter),
            bloomBlur                : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.bloomBlur),
            bloomFinal               : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.bloomFinal),
            splat                    : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.splat),
            advectionManualFiltering : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.advectionManualFiltering),
            advection                : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.advection),
            divergence               : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.divergence),
            curl                     : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.curl),
            vorticity                : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.vorticity),
            pressure                 : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.pressure),
            gradientSubtract         : compileShader(webGL.FRAGMENT_SHADER, defaults.SHADER_SOURCE.gradientSubtract)
        };

        /* Create Shader Programs */
        let programs = {
            clearProgram: new GLProgram(SHADER.vertex, SHADER.clear, webGL),
            colorProgram: new GLProgram(SHADER.vertex, SHADER.color, webGL),
            backgroundProgram: new GLProgram(SHADER.vertex, SHADER.background, webGL),
            displayProgram: new GLProgram(SHADER.vertex, SHADER.display, webGL),
            displayBloomProgram: new GLProgram(SHADER.vertex, SHADER.displayBloom, webGL),
            displayShadingProgram: new GLProgram(SHADER.vertex, SHADER.displayShading, webGL),
            displayBloomShadingProgram: new GLProgram(SHADER.vertex, SHADER.displayBloomShading, webGL),
            bloomPreFilterProgram: new GLProgram(SHADER.vertex, SHADER.bloomPreFilter, webGL),
            bloomBlurProgram: new GLProgram(SHADER.vertex, SHADER.bloomBlur, webGL),
            bloomFinalProgram: new GLProgram(SHADER.vertex, SHADER.bloomFinal, webGL),
            splatProgram: new GLProgram(SHADER.vertex, SHADER.splat, webGL),
            advectionProgram: new GLProgram(SHADER.vertex, colorFormats.supportLinearFiltering ? SHADER.advection : SHADER.advectionManualFiltering, webGL),
            divergenceProgram: new GLProgram(SHADER.vertex, SHADER.divergence, webGL),
            curlProgram: new GLProgram(SHADER.vertex, SHADER.curl, webGL),
            vorticityProgram: new GLProgram(SHADER.vertex, SHADER.vorticity, webGL),
            pressureProgram: new GLProgram(SHADER.vertex, SHADER.pressure, webGL),
            gradientSubtractProgram: new GLProgram(SHADER.vertex, SHADER.gradientSubtract, webGL)
        };

        /* Set globals */
        this.canvas = canvas;
        this.props = props;
        this.programs = programs;
        this.webGL = webGL;
        this.colorFormats = colorFormats;

        /* Worker Classes and Functions */
        /**
         * Get Supported Format
         *  Using the specified internal format, we retrieve and return the desired color format to be
         *  rendered with
         *
         * @param internalFormat: A WebGL constant that specifies the color components within the texture
         * @param format: Another WebGL constant that specifies the color format. In WebGL1 this needs to be the same
         * as internalFormat, in WebGL2 there are format combinations.
         * @param type: Yet another WebGL constant to specify the texel data type
         * @returns Supported formats
         */
        function getSupportedFormat (internalFormat, format, type) {
            let isSupportRenderTextureFormat;
            let texture = webGL.createTexture();

            /* Set texture parameters */
            webGL.bindTexture(webGL.TEXTURE_2D, texture);
            webGL.texParameteri(webGL.TEXTURE_2D, webGL.TEXTURE_MIN_FILTER, webGL.NEAREST);
            webGL.texParameteri(webGL.TEXTURE_2D, webGL.TEXTURE_MAG_FILTER, webGL.NEAREST);
            webGL.texParameteri(webGL.TEXTURE_2D, webGL.TEXTURE_WRAP_S, webGL.CLAMP_TO_EDGE);
            webGL.texParameteri(webGL.TEXTURE_2D, webGL.TEXTURE_WRAP_T, webGL.CLAMP_TO_EDGE);

            /* Create test texture */
            webGL.texImage2D(webGL.TEXTURE_2D, 0, internalFormat, 4, 4, 0, format, type, null);

            /* Attach texture to frame buffer */
            let fbo = webGL.createFramebuffer();
            webGL.bindFramebuffer(webGL.FRAMEBUFFER, fbo);
            webGL.framebufferTexture2D(webGL.FRAMEBUFFER, webGL.COLOR_ATTACHMENT0, webGL.TEXTURE_2D, texture, 0);

            /* Check if current format is supported */
            const status = webGL.checkFramebufferStatus(webGL.FRAMEBUFFER);
            isSupportRenderTextureFormat = status === webGL.FRAMEBUFFER_COMPLETE;

            /* If not supported use fallback format, until we have no fallback */
            if (!isSupportRenderTextureFormat) {
                switch (internalFormat) {
                    case webGL.R16F:
                        return getSupportedFormat(webGL.RG16F, webGL.RG, type);
                    case webGL.RG16F:
                        return getSupportedFormat(webGL.RGBA16F, webGL.RGBA, type);
                    default:
                        return null;
                }
            }

            return { internalFormat, format };
        }

        /**
         * Compile Shader:
         *  Makes a new webGL shader of type `type` using the provided raw GLSL source. The `type` is either of
         *  `VERTEX_SHADER` or `FRAGMENT_SHADER`. This converts the raw shader GLSL source into binary data for the
         *  shader program to use.
         *
         * @param type: Passed to `createShader` to define the shader type
         * @param source: A GLSL source script, used to define the shader properties
         * @returns {WebGLShader}: A webGL shader of the parameterized type and source
         */
        function compileShader (type, source) {
            /* Create shader, link the source, and compile the GLSL */
            const shader = webGL.createShader(type);
            webGL.shaderSource(shader, source);
            webGL.compileShader(shader);

            /* TODO: Finish error checking */
            if (!webGL.getShaderParameter(shader, webGL.COMPILE_STATUS))
                throw webGL.getShaderInfoLog(shader);

            return shader;
        }
    }

    activate () {
        /* Add default pointer */
        let pointers = [];
        pointers.push( new Pointer() );

        /* Localize Globals */
        const CANVAS = this.canvas,
              PARAMS = this.props,
              COLORFORMATS = this.colorFormats,
              PROGRAMS = this.programs,
              WEBGL = this.webGL;

        /**
         *  Frame Buffers for Bloom
         */
        let bloomFrameBuffers = [];

        /**
         *  Holds the Splats at a Give Time
         */
        let splatStack = [];

        /**
         * Simulation Canvas Height and width
         */
        let simHeight, simWidth;

        /**
         * Fluid Emit Height and Width
         */
        let emitHeight, emitWidth;

        /**
         * Fluid Density Frame Buffer: The degree of consistency measured by the quantity of mass per unit volume.
         */
        let density;

        /**
         * Velocity Frame Buffer: The rate of speed the fluid will move with.
         */
        let velocity;

        /**
         * Divergence Frame Buffer: The volume density of the outward flux of a vector field from an infinitesimal volume
         * around a given point.
         */
        let divergence;

        /**
         * Curl Frame Buffer: Spiral curve formation.
         */
        let curl;

        /**
         * Pressure Frame Buffer: Continuous physical force exerted on the fluid.
         */
        let pressure;

        /**
         * Bloom Frame Buffer: The fluid will become radiant and glow.
         */
        let bloom;

        /**
         * Bit-boundary Block Transfer
         *  This will be responsible for transferring information from buffer to buffer.
         *
         * @type {Function}
         */
        const blit = (() => {
            /* Create buffer for some default vertex values */
            WEBGL.bindBuffer(WEBGL.ARRAY_BUFFER, WEBGL.createBuffer());
            WEBGL.bufferData(
                WEBGL.ARRAY_BUFFER,
                new Float32Array([
                    -1, -1,
                    -1,  1,
                     1,  1,
                     1, -1
                ]),
                WEBGL.STATIC_DRAW
            );

            /* Create buffers for element indices */
            WEBGL.bindBuffer(WEBGL.ELEMENT_ARRAY_BUFFER, WEBGL.createBuffer());
            WEBGL.bufferData(
                WEBGL.ELEMENT_ARRAY_BUFFER,
                new Uint16Array([
                    0, 1,
                    2, 0,
                    2, 3
                ]),
                WEBGL.STATIC_DRAW
            );

            /* 👑 Binds buffer on `ARRAY_BUFFER` to a generic vertex attribute on vertex 👑 */
            WEBGL.vertexAttribPointer(0, 2, WEBGL.FLOAT, false, 0, 0);
            WEBGL.enableVertexAttribArray(0);

            return (destination) => {
                /* Bind a framebuffer to our destination */
                WEBGL.bindFramebuffer(WEBGL.FRAMEBUFFER, destination);
                WEBGL.drawElements(WEBGL.TRIANGLES, 6, WEBGL.UNSIGNED_SHORT, 0);
            }
        })();

        /**
         * Dithering Texture
         *  Initialize fluid overlay/dither
         */
        let ditheringTexture = PARAMS.props.embedded_dither ? createTextureAsync(dither.default) : createTextureAsync(ditherURL);

        /* Initialize Fluid */
        initiateFrameBuffers();

        multipleSplats(parseInt(Math.random() * 20) + 3);
        setInterval(() => {
            multipleSplats(parseInt(Math.random() * 15) + 3);
        }, 10000)


        /** Last Color Change Time */
        let lastColorChangeTime = Date.now();
        let counter = 0;

        /** Game Loop **/
        update();
        /** Game Loop **/

        /**
         * Initialize Fluid
         *  Prepares frame buffers for rendering
         *
         */
        function initiateFrameBuffers() {
            /* Localize Color Formats */
            const texType = COLORFORMATS.halfFloatTexType,
                  rgba = COLORFORMATS.formatRGBA,
                  rg = COLORFORMATS.formatRG,
                  r = COLORFORMATS.formatR,
                  filtering = COLORFORMATS.supportLinearFiltering ? WEBGL.LINEAR : WEBGL.NEAREST;

            /* Set resolutions */
            let simRes = getResolution(PARAMS.props.sim_resolution),
                emitRes = getResolution(PARAMS.props.dye_resolution),
                bloomRes = getResolution(PARAMS.props.bloom_resolution);

            /* Simulation Size */
            simWidth = simRes.width;
            simHeight = simRes.height;

            /* Emitter Size */
            emitWidth = emitRes.width;
            emitHeight = emitRes.height;

            /* Create or Resize Density Double Frame Buffer */
            density = !density ?
                createDoubleFBO(emitWidth, emitHeight, rgba.internalFormat, rgba.format, texType, filtering) :
                resizeDoubleFBO(density, emitWidth, emitHeight, rgba.internalFormat, rgba.format, texType, filtering);

            /* Create or Resize Velocity Double Frame Buffer */
            velocity = !velocity ?
                createDoubleFBO(simWidth, simHeight, rg.internalFormat, rg.format, texType, filtering) :
                resizeDoubleFBO(velocity, simWidth, simHeight, rg.internalFormat, rg.format, texType, filtering);

            /* Create Bloom Double Frame Buffer */
            bloom = createFBO(bloomRes.width, bloomRes.height, rgba.internalFormat, rgba.format, texType, filtering);

            /* Create Divergence Frame Buffer */
            divergence = createFBO(simWidth, simHeight, r.internalFormat, r.format, texType, WEBGL.NEAREST);

            /* Create Curl Frame Buffer */
            curl = createFBO(simWidth, simHeight, r.internalFormat, r.format, texType, WEBGL.NEAREST);

            /* Create Pressure Frame Buffer */
            pressure = createDoubleFBO(simWidth, simHeight, r.internalFormat, r.format, texType, WEBGL.NEAREST);

            /* Populate bloom's frame buffer stack by iterating through bloom iterations
            *  Each iteration, we offset the scale linearly at a constant rate*/
            bloomFrameBuffers.length = 0;
            for (let i = 0; i < PARAMS.props.bloom_iterations; i++) {
                /* Offset scale by a factor of 1 plus our current iteration*/
                let width = bloomRes.width >> (i + 1);
                let height = bloomRes.height >> (i + 1);

                /* Don't create frame buffer */
                if (width < 2 || height < 2) break;

                /* Create Frame Buffer for Bloom iteration */
                let fbo = createFBO(width, height, rgba.internalFormat, rgba.format, texType, filtering);

                /* Add Frame Buffer to Stack */
                bloomFrameBuffers.push(fbo);
            }
        }

        /**
         * Create Double Frame Buffer Object
         *  Creates an object with 2 frame buffers, one for reads and one for writes
         *
         * @param w: Width
         * @param h: Height
         * @param internalFormat: Internal color formats
         * @param format: Color format
         * @param type: Texture type
         * @param filteringParam: Filtering parameter
         */
        function createDoubleFBO(w, h, internalFormat, format, type, filteringParam) {
            /* Create Read and Write Frame Buffer Objects */
            let fbo1 = createFBO(w, h, internalFormat, format, type, filteringParam);
            let fbo2 = createFBO(w, h, internalFormat, format, type, filteringParam);

            return {
                /* Getter and Setter for Read Frame Buffer */
                get read() { return fbo1; },
                set read(value) { fbo1 = value; },

                /* Getter and Setter for Write Frame Buffer */
                get write() { return fbo2; },
                set write(value) { fbo2 = value; },

                /**
                 * Swap:
                 *  Swaps data between frame buffers
                 */
                swap() {
                    let temp = fbo1;
                    fbo1 = fbo2;
                    fbo2 = temp;
                }
            }
        }

        /**
         * Create Frame Buffer
         *
         *
         * @param w: Width
         * @param h: Height
         * @param internalFormat: Internal color formats
         * @param format: Color format
         * @param type: Texture type
         * @param filteringParam: Filtering parameter
         */
        function createFBO(w, h, internalFormat, format, type, filteringParam) {
            /* Activate texture 0 to be modified */
            WEBGL.activeTexture(WEBGL.TEXTURE0);

            // Create new texture
            let texture = WEBGL.createTexture();
            WEBGL.bindTexture(WEBGL.TEXTURE_2D, texture);

            // Set minification and magnification filter
            WEBGL.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_MIN_FILTER, filteringParam);
            WEBGL.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_MAG_FILTER, filteringParam);

            // Set wrapping functions
            WEBGL.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_WRAP_S, WEBGL.CLAMP_TO_EDGE);
            WEBGL.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_WRAP_T, WEBGL.CLAMP_TO_EDGE);

            // Bind new texture
            WEBGL.texImage2D(WEBGL.TEXTURE_2D, 0, internalFormat, w, h, 0, format, type, null);

            // Create frame buffer
            let fbo = WEBGL.createFramebuffer();
            WEBGL.bindFramebuffer(WEBGL.FRAMEBUFFER, fbo);

            // Set texture on framebuffer
            WEBGL.framebufferTexture2D(WEBGL.FRAMEBUFFER, WEBGL.COLOR_ATTACHMENT0, WEBGL.TEXTURE_2D, texture, 0);
            WEBGL.viewport(0, 0, w, h);
            WEBGL.clear(WEBGL.COLOR_BUFFER_BIT);

            // Returns framebuffer objecth
            return {
                texture,
                fbo,
                width: w,
                height: h,
                attach(id) {
                    WEBGL.activeTexture(WEBGL.TEXTURE0 + id);
                    WEBGL.bindTexture(WEBGL.TEXTURE_2D, texture);
                    return id;
                }
            };
        }

        function resizeDoubleFBO(target, w, h, internalFormat, format, type, param) {
            target.read = resizeFBO(target.read, w, h, internalFormat, format, type, param);
            target.write = createFBO(w, h, internalFormat, format, type, param);
            return target;
        }

        function resizeFBO(target, w, h, internalFormat, format, type, param) {
            let newFBO = createFBO(w, h, internalFormat, format, type, param);
            PROGRAMS.clearProgram.bind();
            WEBGL.uniform1i(PROGRAMS.clearProgram.uniforms.uTexture, target.attach(0));
            WEBGL.uniform1f(PROGRAMS.clearProgram.uniforms.value, 1);
            blit(newFBO.fbo);
            return newFBO;
        }

        function createTextureAsync(url) {
            let texture = WEBGL.createTexture();
            WEBGL.bindTexture(WEBGL.TEXTURE_2D, texture);
            WEBGL.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_MIN_FILTER, WEBGL.LINEAR);
            WEBGL.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_MAG_FILTER, WEBGL.LINEAR);
            WEBGL.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_WRAP_S, WEBGL.REPEAT);
            WEBGL.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_WRAP_T, WEBGL.REPEAT);
            WEBGL.texImage2D(WEBGL.TEXTURE_2D, 0, WEBGL.RGB, 1, 1, 0, WEBGL.RGB, WEBGL.UNSIGNED_BYTE, new Uint8Array([255, 255, 255]));

            let obj = {
                texture,
                width: 1,
                height: 1,
                attach(id) {
                    WEBGL.activeTexture(WEBGL.TEXTURE0 + id);
                    WEBGL.bindTexture(WEBGL.TEXTURE_2D, texture);
                    return id;
                }
            };

            let image = new Image();

            image.src = url;

            image.onload = () => {
                obj.width = image.width;
                obj.height = image.height;
                WEBGL.bindTexture(WEBGL.TEXTURE_2D, texture);
                WEBGL.texImage2D(WEBGL.TEXTURE_2D, 0, WEBGL.RGB, WEBGL.RGB, WEBGL.UNSIGNED_BYTE, image);
            };

            return obj;
        }

        function update() {
            counter++;

            /* If Behavior State Changed, Update Our Parameters */
            resizeCanvas();

            input();

            if (!PARAMS.props.paused)
                step(0.016);
            render(null);
            let callback = requestAnimationFrame(update);

            /* Destroys if Deactivated */
            if (PARAMS.props.cancel) {
                WEBGL.clear(WEBGL.COLOR_BUFFER_BIT);
                cancelAnimationFrame(callback);
            }
        }

        function input() {
            if (splatStack.length > 0)
                multipleSplats(splatStack.pop());

            for (let i = 0; i < pointers.length; i++) {
                const p = pointers[i];

                if (p.moved) {
                    splat(p.x, p.y, p.dx, p.dy, p.color);

                    if(i !== 1)
                        p.moved = false;
                }
            }

            if (!PARAMS.props.multi_color)
                return;

            if (lastColorChangeTime + 100 < Date.now()) {
                lastColorChangeTime = Date.now();

                for (const p of pointers) {
                    p.color = generateColor();
                }
            }
        }

        function step(dt) {
            WEBGL.disable(WEBGL.BLEND);
            WEBGL.viewport(0, 0, simWidth, simHeight);

            PROGRAMS.curlProgram.bind();
            WEBGL.uniform2f(PROGRAMS.curlProgram.uniforms.texelSize, 1.0 / simWidth, 1.0 / simHeight);
            WEBGL.uniform1i(PROGRAMS.curlProgram.uniforms.uVelocity, velocity.read.attach(0));
            blit(curl.fbo);

            PROGRAMS.vorticityProgram.bind();
            WEBGL.uniform2f(PROGRAMS.vorticityProgram.uniforms.texelSize, 1.0 / simWidth, 1.0 / simHeight);
            WEBGL.uniform1i(PROGRAMS.vorticityProgram.uniforms.uVelocity, velocity.read.attach(0));
            WEBGL.uniform1i(PROGRAMS.vorticityProgram.uniforms.uCurl, curl.attach(1));
            WEBGL.uniform1f(PROGRAMS.vorticityProgram.uniforms.curl, PARAMS.props.curl);
            WEBGL.uniform1f(PROGRAMS.vorticityProgram.uniforms.dt, dt);
            blit(velocity.write.fbo);
            velocity.swap();

            PROGRAMS.divergenceProgram.bind();
            WEBGL.uniform2f(PROGRAMS.divergenceProgram.uniforms.texelSize, 1.0 / simWidth, 1.0 / simHeight);
            WEBGL.uniform1i(PROGRAMS.divergenceProgram.uniforms.uVelocity, velocity.read.attach(0));
            blit(divergence.fbo);

            PROGRAMS.clearProgram.bind();
            WEBGL.uniform1i(PROGRAMS.clearProgram.uniforms.uTexture, pressure.read.attach(0));
            WEBGL.uniform1f(PROGRAMS.clearProgram.uniforms.value, PARAMS.props.pressure);
            blit(pressure.write.fbo);
            pressure.swap();

            PROGRAMS.pressureProgram.bind();
            WEBGL.uniform2f(PROGRAMS.pressureProgram.uniforms.texelSize, 1.0 / simWidth, 1.0 / simHeight);
            WEBGL.uniform1i(PROGRAMS.pressureProgram.uniforms.uDivergence, divergence.attach(0));
            for (let i = 0; i < PARAMS.props.pressure_iteration; i++) {
                WEBGL.uniform1i(PROGRAMS.pressureProgram.uniforms.uPressure, pressure.read.attach(1));
                blit(pressure.write.fbo);
                pressure.swap();
            }

            PROGRAMS.gradientSubtractProgram.bind();
            WEBGL.uniform2f(PROGRAMS.gradientSubtractProgram.uniforms.texelSize, 1.0 / simWidth, 1.0 / simHeight);
            WEBGL.uniform1i(PROGRAMS.gradientSubtractProgram.uniforms.uPressure, pressure.read.attach(0));
            WEBGL.uniform1i(PROGRAMS.gradientSubtractProgram.uniforms.uVelocity, velocity.read.attach(1));
            blit(velocity.write.fbo);
            velocity.swap();

            PROGRAMS.advectionProgram.bind();
            WEBGL.uniform2f(PROGRAMS.advectionProgram.uniforms.texelSize, 1.0 / simWidth, 1.0 / simHeight);
            if (!COLORFORMATS.supportLinearFiltering)
                WEBGL.uniform2f(PROGRAMS.advectionProgram.uniforms.dyeTexelSize, 1.0 / simWidth, 1.0 / simHeight);
            let velocityId = velocity.read.attach(0);
            WEBGL.uniform1i(PROGRAMS.advectionProgram.uniforms.uVelocity, velocityId);
            WEBGL.uniform1i(PROGRAMS.advectionProgram.uniforms.uSource, velocityId);
            WEBGL.uniform1f(PROGRAMS.advectionProgram.uniforms.dt, dt);
            WEBGL.uniform1f(PROGRAMS.advectionProgram.uniforms.dissipation, PARAMS.props.velocity);
            blit(velocity.write.fbo);
            velocity.swap();

            WEBGL.viewport(0, 0, emitWidth, emitHeight);

            if (!COLORFORMATS.supportLinearFiltering)
                WEBGL.uniform2f(PROGRAMS.advectionProgram.uniforms.dyeTexelSize, 1.0 / emitWidth, 1.0 / emitHeight);
            WEBGL.uniform1i(PROGRAMS.advectionProgram.uniforms.uVelocity, velocity.read.attach(0));
            WEBGL.uniform1i(PROGRAMS.advectionProgram.uniforms.uSource, density.read.attach(1));
            WEBGL.uniform1f(PROGRAMS.advectionProgram.uniforms.dissipation, PARAMS.props.dissipation);
            blit(density.write.fbo);
            density.swap();
        }

        function render(target) {
            if (PARAMS.props.render_bloom)
                applyBloom(density.read, bloom);

            if (target == null || !PARAMS.props.transparent) {
                WEBGL.blendFunc(WEBGL.ONE, WEBGL.ONE_MINUS_SRC_ALPHA);
                WEBGL.enable(WEBGL.BLEND);
            } else {
                WEBGL.disable(WEBGL.BLEND);
            }

            let width = target == null ? WEBGL.drawingBufferWidth : emitWidth;
            let height = target == null ? WEBGL.drawingBufferHeight : emitHeight;

            WEBGL.viewport(0, 0, width, height);

            if (!PARAMS.props.transparent) {
                PROGRAMS.colorProgram.bind();
                let bc = PARAMS.props.background_color;
                WEBGL.uniform4f(PROGRAMS.colorProgram.uniforms.color, bc.r / 255, bc.g / 255, bc.b / 255, 1);
                blit(target);
            }

            if (target == null && PARAMS.props.transparent) {
                PROGRAMS.backgroundProgram.bind();
                WEBGL.uniform1f(PROGRAMS.backgroundProgram.uniforms.aspectRatio, CANVAS.width / CANVAS.height);
                blit(null);
            }

            if (PARAMS.props.render_shaders) {
                let program = PARAMS.props.render_bloom ? PROGRAMS.displayBloomShadingProgram : PROGRAMS.displayShadingProgram;
                program.bind();
                WEBGL.uniform2f(program.uniforms.texelSize, 1.0 / width, 1.0 / height);
                WEBGL.uniform1i(program.uniforms.uTexture, density.read.attach(0));
                if (PARAMS.props.render_bloom) {
                    WEBGL.uniform1i(program.uniforms.uBloom, bloom.attach(1));
                    WEBGL.uniform1i(program.uniforms.uDithering, ditheringTexture.attach(2));
                    let scale = getTextureScale(ditheringTexture, width, height);
                    WEBGL.uniform2f(program.uniforms.ditherScale, scale.x, scale.y);
                }
            } else {
                let program = PARAMS.props.render_bloom ? PROGRAMS.displayBloomProgram : PROGRAMS.displayProgram;
                program.bind();
                WEBGL.uniform1i(program.uniforms.uTexture, density.read.attach(0));
                if (PARAMS.props.render_bloom) {
                    WEBGL.uniform1i(program.uniforms.uBloom, bloom.attach(1));
                    WEBGL.uniform1i(program.uniforms.uDithering, ditheringTexture.attach(2));
                    let scale = getTextureScale(ditheringTexture, width, height);
                    WEBGL.uniform2f(program.uniforms.ditherScale, scale.x, scale.y);
                }
            }

            blit(target);
        }

        function applyBloom(source, destination) {
            if (bloomFrameBuffers.length < 2)
                return;

            let last = destination;

            WEBGL.disable(WEBGL.BLEND);
            PROGRAMS.bloomPreFilterProgram.bind();
            let knee = PARAMS.props.threshold * PARAMS.props.soft_knee + 0.0001;
            let curve0 = PARAMS.props.threshold - knee;
            let curve1 = knee * 2;
            let curve2 = 0.25 / knee;
            WEBGL.uniform3f(PROGRAMS.bloomPreFilterProgram.uniforms.curve, curve0, curve1, curve2);
            WEBGL.uniform1f(PROGRAMS.bloomPreFilterProgram.uniforms.threshold, PARAMS.props.threshold);
            WEBGL.uniform1i(PROGRAMS.bloomPreFilterProgram.uniforms.uTexture, source.attach(0));
            WEBGL.viewport(0, 0, last.width, last.height);
            blit(last.fbo);

            PROGRAMS.bloomBlurProgram.bind();
            for (let i = 0; i < bloomFrameBuffers.length; i++) {
                let dest = bloomFrameBuffers[i];
                WEBGL.uniform2f(PROGRAMS.bloomBlurProgram.uniforms.texelSize, 1.0 / last.width, 1.0 / last.height);
                WEBGL.uniform1i(PROGRAMS.bloomBlurProgram.uniforms.uTexture, last.attach(0));
                WEBGL.viewport(0, 0, dest.width, dest.height);
                blit(dest.fbo);
                last = dest;
            }

            WEBGL.blendFunc(WEBGL.ONE, WEBGL.ONE);
            WEBGL.enable(WEBGL.BLEND);

            for (let i = bloomFrameBuffers.length - 2; i >= 0; i--) {
                let baseTex = bloomFrameBuffers[i];
                WEBGL.uniform2f(PROGRAMS.bloomBlurProgram.uniforms.texelSize, 1.0 / last.width, 1.0 / last.height);
                WEBGL.uniform1i(PROGRAMS.bloomBlurProgram.uniforms.uTexture, last.attach(0));
                WEBGL.viewport(0, 0, baseTex.width, baseTex.height);
                blit(baseTex.fbo);
                last = baseTex;
            }

            WEBGL.disable(WEBGL.BLEND);
            PROGRAMS.bloomFinalProgram.bind();
            WEBGL.uniform2f(PROGRAMS.bloomFinalProgram.uniforms.texelSize, 1.0 / last.width, 1.0 / last.height);
            WEBGL.uniform1i(PROGRAMS.bloomFinalProgram.uniforms.uTexture, last.attach(0));
            WEBGL.uniform1f(PROGRAMS.bloomFinalProgram.uniforms.intensity, PARAMS.props.intensity);
            WEBGL.viewport(0, 0, destination.width, destination.height);
            blit(destination.fbo);
        }

        function splat(x, y, dx, dy, color) {
            WEBGL.viewport(0, 0, simWidth, simHeight);
            PROGRAMS.splatProgram.bind();
            WEBGL.uniform1i(PROGRAMS.splatProgram.uniforms.uTarget, velocity.read.attach(0));
            WEBGL.uniform1f(PROGRAMS.splatProgram.uniforms.aspectRatio, CANVAS.width / CANVAS.height);
            WEBGL.uniform2f(PROGRAMS.splatProgram.uniforms.point, x / CANVAS.width, 1.0 - y / CANVAS.height);
            WEBGL.uniform3f(PROGRAMS.splatProgram.uniforms.color, dx, -dy, 1.0);
            WEBGL.uniform1f(PROGRAMS.splatProgram.uniforms.radius, PARAMS.props.emitter_size / 100.0);
            blit(velocity.write.fbo);
            velocity.swap();

            WEBGL.viewport(0, 0, emitWidth, emitHeight);
            WEBGL.uniform1i(PROGRAMS.splatProgram.uniforms.uTarget, density.read.attach(0));
            WEBGL.uniform3f(PROGRAMS.splatProgram.uniforms.color, color.r, color.g, color.b);
            blit(density.write.fbo);
            density.swap();
        }

        function multipleSplats(amount) {
            for (let i = 0; i < amount; i++) {
                const color = generateColor();
                color.r *= 10.0;
                color.g *= 10.0;
                color.b *= 10.0;
                const x = CANVAS.width * Math.random();
                const y = CANVAS.height * Math.random();
                const dx = 1500 * (Math.random() - 0.5);
                const dy = 1500 * (Math.random() - 0.5);
                splat(x, y, dx, dy, color);
            }

            // const color = generateColor();
            //
            // splat(200, 350, 500, 0, color);
        }

        function resizeCanvas() {
            if (CANVAS.width !== CANVAS.clientWidth || CANVAS.height !== CANVAS.clientHeight) {
                CANVAS.width = CANVAS.clientWidth;
                CANVAS.height = CANVAS.clientHeight;
                initiateFrameBuffers();
            }
        }

        function generateColor() {
            let c = HSVtoRGB(Math.random(), 1.0, 1.0);
            c.r *= 0.15;
            c.g *= 0.15;
            c.b *= 0.15;
            return c;
        }

        function HSVtoRGB(h, s, v) {
            let r, g, b, i, f, p, q, t;
            i = Math.floor(h * 6);
            f = h * 6 - i;
            p = v * (1 - s);
            q = v * (1 - f * s);
            t = v * (1 - (1 - f) * s);

            switch (i % 6) {
                case 0:
                    r = v, g = t, b = p;
                    break;
                case 1:
                    r = q, g = v, b = p;
                    break;
                case 2:
                    r = p, g = v, b = t;
                    break;
                case 3:
                    r = p, g = q, b = v;
                    break;
                case 4:
                    r = t, g = p, b = v;
                    break;
                case 5:
                    r = v, g = p, b = q;
                    break;
            }

            return {
                r,
                g,
                b
            };
        }

        function getResolution(resolution) {
            let aspectRatio = WEBGL.drawingBufferWidth / WEBGL.drawingBufferHeight;
            if (aspectRatio < 1)
                aspectRatio = 1.0 / aspectRatio;

            let max = Math.round(resolution * aspectRatio);
            let min = Math.round(resolution);

            if (WEBGL.drawingBufferWidth > WEBGL.drawingBufferHeight)
                return {width: max, height: min};
            else
                return {width: min, height: max};
        }

        function getTextureScale(texture, width, height) {
            return {
                x: width / texture.width,
                y: height / texture.height
            };
        }


        window.addEventListener('mousemove', e => {
            if (counter>5) {
                pointers[0].down = true;
                pointers[0].moved = pointers[0].down;
                pointers[0].dx = (e.clientX - pointers[0].x) * 5.0;
                pointers[0].dy = (e.clientY - pointers[0].y) * 5.0;
                pointers[0].x = e.clientX;
                pointers[0].y = e.clientY;

            }
        });

        window.addEventListener('mousedown', () => {
            pointers[0].down = true;
            pointers[0].color = generateColor();
        });

        window.addEventListener('mouseup', () => {
            pointers[0].down = false;
        });

        window.addEventListener('keydown', e => {
            if (e.code === 'KeyP')
                PARAMS.props.paused = !PARAMS.props.paused;
            if (e.key === ' ')
                splatStack.push(parseInt(Math.random() * 20) + 5);
        });

        window.addEventListener('touchstart', e => {
            // e.preventDefault();
            const touches = e.targetTouches;
            for (let i = 0; i < touches.length; i++) {
                if (i >= pointers.length)
                    pointers.push(new Pointer());

                pointers[i].id = touches[i].identifier;
                pointers[i].down = true;
                pointers[i].x = touches[i].pageX;
                pointers[i].y = touches[i].pageY;
                pointers[i].color = generateColor();
            }
        });

        window.addEventListener('touchmove', e => {
            // e.preventDefault();
            const touches = e.targetTouches;
            for (let i = 0; i < touches.length; i++) {
                let pointer = pointers[i];
                pointer.moved = pointer.down;
                pointer.dx = (touches[i].pageX - pointer.x) * 8.0;
                pointer.dy = (touches[i].pageY - pointer.y) * 8.0;
                pointer.x = touches[i].pageX;
                pointer.y = touches[i].pageY;
            }
        }, false);

        window.addEventListener('touchend', e => {
            const touches = e.changedTouches;
            for (let i = 0; i < touches.length; i++)
                for (let j = 0; j < pointers.length; j++)
                    if (touches[i].identifier === pointers[j].id)
                        pointers[j].down = false;
        });

        window.addEventListener('splat_F', () => {
            multipleSplats(parseInt(Math.random() * 10) + 2);
        })
    }

    /**
     * Set Cancel
     *  Cancel state setter.
     * @param value
     */
    setCancel(value) { this.cancel = value; }
}

export function setDitherURL(url) { ditherURL = url; }

class GLProgram {
    constructor (vertexShader, fragmentShader, webGL) {
        this.uniforms = {};
        this.webGL = webGL;
        this.program = webGL.createProgram();

        webGL.attachShader(this.program, vertexShader);
        webGL.attachShader(this.program, fragmentShader);
        webGL.linkProgram(this.program);

        if (!webGL.getProgramParameter(this.program, webGL.LINK_STATUS))
            throw webGL.getProgramInfoLog(this.program);

        const uniformCount = webGL.getProgramParameter(this.program, webGL.ACTIVE_UNIFORMS);
        for (let i = 0; i < uniformCount; i++) {
            const uniformName = webGL.getActiveUniform(this.program, i).name;
            this.uniforms[uniformName] = webGL.getUniformLocation(this.program, uniformName);
        }
    }

    bind () {
        this.webGL.useProgram(this.program);
    }
}

class Pointer {
    constructor () {
        /**
         * Identifier for the pointer object
         *
         *  @type {number} valid IDs are always either zero or a positive integer (-1 is invalid and should
         *  be managed upon creation of a new pointer object.)
         */
        this.id = -1;

        /**
         * Horizontal (x) and vertical (y) position of the pointer
         *
         *  @type {number}
         */
        this.x = 0;
        this.y = 0;

        /**
         * Velocity data describing the positional change in the horizontal (x) and vertical (y) axis of
         *  this pointer
         *
         * @type {number}
         */
        this.dx = 0;
        this.dy = 0;

        /**
         * Boolean data member used to store whether or not the pointer is in a clicked state and/or a
         *  moving state
         *
         *  @type {boolean}
         */
        this.down = true;
        this.moved = false;

        /**
         * The color the pointer will render as
         *
         * @type {number[]}
         */
        this.color = [30, 0, 300];
    }
}
