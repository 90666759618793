import './App.css';
import React from "react";

import Fluid from './FluidJS/fluid.js';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        const canvas = document.getElementById('renderSurface');

        this.block(canvas);

        let myFluid = new Fluid(canvas);

        myFluid.mapBehaviors({
            sim_resolution: 512,
            dye_resolution: 1048,

            paused: false,
            embedded_dither: false,

            dissipation: .997,
            velocity: .998,
            pressure: .5,
            curl: 20,
            emitter_size: 0.65,

            render_shaders: true,
            multi_color: true,

            render_bloom: false,
            bloom_iterations: 4,
            bloom_resolution: 1048,

            transparent: true
        });

        myFluid.activate();
    }

    block = (c) => {
        const prevent = (e) => {
            e.stopPropagation();
            e.preventDefault();
        }

        c.addEventListener('mousemove', prevent);
        c.addEventListener('mousedown', prevent);
        c.addEventListener('mouseup', prevent);
        c.addEventListener('keydown', prevent);
        c.addEventListener('touchstart', prevent);
        c.addEventListener('touchmove', prevent);
        c.addEventListener('touchend', prevent);

        setTimeout(() => {
            c.removeEventListener('mousemove', prevent);
            c.removeEventListener('mousedown', prevent);
            c.removeEventListener('mouseup', prevent);
            c.removeEventListener('keydown', prevent);
            c.removeEventListener('touchstart', prevent);
            c.removeEventListener('touchmove', prevent);
            c.removeEventListener('touchend', prevent);
        }, 200)
    }

    render() {
        return (
            <>
                <canvas id="renderSurface" ref={this.ref}></canvas>

                <div className="App">
                    <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                        <path
                            d="M0,365.116H131l227-241.86v241.86H500V0H323.4ZM64,469.767H50V365.116H0V500H103V459.3H64v10.465ZM212,365.116H103V500H212V472.093H151V444.186h33V415l-33,.116V395.349h61V365.116Zm106,0H212V500H318V470.93H259V444.186h32V413.953H259V394.186h59v-29.07ZM368,500V365.116H318V500h50ZM500,365.116H439L375,437l64,63h61l-72-67Z"></path>
                    </svg>
                </div>


            </>

        );
    }
}

export default App;
